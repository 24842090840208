<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      <!-- <div class="mr-3">
        <h1>
          Registering with
          <div class="text-capitalize">
            {{ $route.params.provider }}
          </div>
          please wait!
        </h1>
      </div> -->
      <div class="text-center my-5 empty-data"
          >
            <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="200" class="mr-50" style="background-color: transparent" />
            <h4> Loading </h4>
            <p class="d-block mb-1">Please Wait</p>
          </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BSpinner,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Other imports
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import Vue from "vue";
import { $themeConfig } from "@themeConfig";
import axios from "@axios";
const NavBar = () => import("@/components/NavBar.vue");
import AppFooter from "@core/layouts/components/AppFooter.vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    NavBar,
    AppFooter,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  props: {
    code: String,
  },
  data() {
    return {
      skin: store.state.appConfig.layout.skin,
      required,
      email,
      data_local: {
        company_name: "",
        company_email: "",
        company_phone: "",
        company_website: "",
        company_address: "",
        company_logo: "",
      },
      state:null
    };
  },

  created() {
    console.log(window.location);
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('state')){
      this.state = urlParams.get('state');
      var url = window.location.href;
      if(window.location.hostname == '127.0.0.1'){
        window.location.href = url.replace('127.0.0.1:8080/register/',this.state+':8080/authorize/');
      }
      else if(window.location.hostname == 'bbdev.dckap.co'){
        window.location.href = url.replace('bbdev.dckap.co/register/',this.state+'/authorize/');
      }
      else if(window.location.hostname == 'login.zircly.com'){
        window.location.href = url.replace('login.zircly.com/register/',this.state+'/authorize/');
      }
      else if(window.location.hostname == 'localhost'){
        window.location.href = url.replace('/register/','/authorize/');
      }
    }else{
      if(window.location.hostname == 'localhost' ){
        var url = window.location.href;
        window.location.href = url.replace('localhost','127.0.0.1');
      }
      else{
        
        if (window.location.href.indexOf('google') !== -1) {
          this.loginUser('google');
        }
        else if (window.location.href.indexOf('facebook') !== -1) {
          this.loginUser('facebook');
        }
        else if (window.location.href.indexOf('microsoft') !== -1) {
          this.loginUser('microsoft');
        }
        else if (window.location.href.indexOf('yahoo') !== -1) {
          this.loginUser('yahoo');
        }
      }
    }
    // if(window.location.host == 'localhost:8080' ){
    //   var url = window.location.href;
    //   window.location.href = url.replace('localhost:8080','127.0.0.1:8080');
    // }else{
      
    // }
    
  },
  methods: {
    async AfterLogin(response) {
      const userData = response.data.data;
      // localStorage.setItem("userData", JSON.stringify(userData));
      this.$cookies.set(
        "userData",
        userData,
        60 * process.env.VUE_APP_LOGIN_TIMEOUT
      ); // 60 minutes
      axios.get("abilities").then((response) => {
        this.$ability.update([{ subject: "all", actions: response.data.data }]);
        
      });
      
      console.log(getHomeRouteForLoggedInUser(userData.role));
      this.$router
        .replace(getHomeRouteForLoggedInUser(userData.role))
        .then(() => {
          // this.$toast({
          //   component: ToastificationContent,
          //   position: "top-right",
          //   props: {
          //     title: `Welcome ${userData.name}`,
          //     icon: "CoffeeIcon",
          //     variant: "success",
          //     text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Welcome ${userData.name}`,
                icon: 'BellIcon',
                variant: 'success',
                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

        })
        .catch((error) => {
          console.log(error);
          // this.$refs.log.setErrors(error.response.data.error);
        });
    },
    loginUser(provider = "google") {
      const self = this;
      let data = {};
      data.url = "register/" + provider + "/callback";
      data.params = {
        code: this.$route.query.code
      };
      this.$store
        .dispatch("app/get", data)
        .then((response) => {
          console.log(response);
          if(response.data.target && response.data.target == 'Login'){
            this.$router.push({ name: 'auth-login' });
          }
          else if(response.data.target && response.data.target == 'RegistrationPage2'){
            this.$router.push({ name: "onboarding" ,params:{'email':response.data.data.email}});
          }          
        })
        .catch((err) => {
          this.$router.push({ name: "onboarding" });
          // this.$toast({
          //   component: ToastificationContent,
          //   position: "top-right",
          //   props: {
          //     title: "Unable to register with email",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.response.data.message,
          //   },
          // });

          this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Unable to register with email",
                  icon:'BellIcon',
                  variant : 'danger',
                  hideClose: true,
                  text:err.response.data.message
                  
                },
              },
              {
                timeout : 2000,
                position : 'bottom-center',
                toastClassName:"radius-8 error-info",
                hideProgressBar : true,
              })
        });
      return;
    },
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app;
    return {
      appName,
      appDarkLogo,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.brand-text {
  line-height: 42px;
  margin-bottom: 0;
}

footer.login-page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f8f8f8;
  z-index: 999;
}
</style>
